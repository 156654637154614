// @ts-nocheck
import axios from 'axios'
import { isString } from 'lodash'
import {
  all,
  call,
  put,
  race,
  select,
  takeLatest,
  takeLeading,
  takeEvery,
  delay,
} from '@redux-saga/core/effects'

import { Qs, formatDate, getSavedFilterList, getSavedSortOrder, roleToLabel } from '../../utils'

import {
  GET_TASKS,
  GET_OPENSEARCH_TASKS,
  GET_DASHBOARD_OPENSEARCH_TASKS,
  GET_TASKS_SILENT,
  GET_TASKS_SUCCESS,
  GET_TASKS_ERROR,
  POST_NOTES,
  POST_NOTES_SUCCESS,
  POST_NOTES_ERROR,
  UPDATE_TASK,
  UPDATE_TASK_ERROR,
  UPDATE_TASK_SUCCESS,
  CREATE_TASK,
  CREATE_TASK_ERROR,
  CREATE_TASK_SUCCESS,
  GET_NOTES_FOR_TASK,
  GET_NOTES_FOR_TASK_SUCCESS,
  GET_NOTES_FOR_TASK_ERROR,
  GET_TASK_SUCCESS,
  GET_TASK_ERROR,
  GET_DASHBOARD_TASKS_SUCCESS,
  GET_DASHBOARD_TASKS_ERROR,
  GET_TASK,
  GET_OPENSEARCH_TASKS_BY_WELL,
  GET_OPENSEARCH_TASKS_BY_WELL_SUCCESS,
  GET_OPENSEARCH_TASKS_BY_WELL_ERROR,
  CLEAR_OPENSEARCH_TASKS,
  GET_WELLS_BY_OPENTASKS,
  GET_WELLS_BY_OPENTASKS_SUCCESS,
  GET_WELLS_BY_OPENTASKS_ERROR,
} from '../../actions/actionTypes'
import { enqueueErrorMessage } from '../../actions/notification'
import * as suspensions from '../../actions/suspensions'
import { GetTaskAction, GetTasksAction } from '../../actions/tasksTypes'

import { apiUrl, updateTask, createTask, searchTasks } from '../../api/tasks'
import { apiGetter } from '../../api/apiGetter'
import { getQueryByAllFilters } from './openSearchQueries'

import { STATUS } from '../../constants'
import { DEFAULT_ROWS_PER_PAGE } from '../../components/TabsTasksTable/constants'
import { fetchOpenSearchTasksByWell } from '../../actions/tasks'

const { TO_DO, IN_PROGRESS, COMPLETED, DISREGARDED, EXPIRED, ESCALATED, CLOSED } = STATUS

// Due to bugs in material-table we can't use columns 'render' with dynamic data in the columns
// e.g. it causes sorting reset when opening Detail Panel
// The workaround is to format data here before saving to Redux store
// Dimitri 12/10/20
const mapTask = task => ({
  ...task,
  role: roleToLabel(task.role),
  createdAt: formatDate(task.createdAt),
  updatedAt: formatDate(task.updatedAt),
  expiry: formatDate(task.expiry),
})

function* getTaskSaga({ payload: request = {} }: GetTaskAction) {
  try {
    const user = yield select(state => state.auth.data)
    const tasks = yield call(apiGetter, apiUrl, { ...request })
    yield put({ type: GET_TASK_SUCCESS, payload: tasks.map(mapTask)[0], user })
  } catch (error) {
    yield all([
      put({ type: GET_TASK_ERROR, payload: error }),
      put(enqueueErrorMessage({ message: 'Failed to load Task', error })),
    ])
  }
}

function* getTasksSaga({ payload: request = {} }: GetTasksAction) {
  const statuses = isString(request.status) ? [request.status] : request.status

  try {
    const requests = statuses.map(sts => call(apiGetter, apiUrl, { ...request, status: sts }))

    let tasksByStatus = {}
    const response = yield all(requests)

    for (const status of statuses) {
      const index = statuses.indexOf(status)

      tasksByStatus = {
        ...tasksByStatus,
        [status]: response[index].map(mapTask),
      }
    }

    yield put({ type: GET_TASKS_SUCCESS, payload: tasksByStatus })
  } catch (error) {
    yield all([
      put({ type: GET_TASKS_ERROR, payload: error }),
      put(enqueueErrorMessage({ message: 'Failed to load Tasks', error })),
    ])
  }
}

function* getOpenSearchTasksByWellSaga({ payload: request = {} }: GetTasksAction) {
  const statuses = isString(request.status) ? [request.status] : request.status
  const {
    rowsPerPage,
    currentPage,
    searchText,
    localFilters,
    wellId,
    isMyRolesToggleChecked,
    sortOrder,
    applyGlobalFilters,
    sort = true,
    fields = null,
  } = request

  const authData = yield select(state => state?.auth?.data)
  const tasksUseMyRolesToggleValue = yield select(
    state => state?.config?.settings?.tasks?.useMyRoles
  )
  const globalFilters = yield select(state => state?.filters?.filters)
  const userName = authData?.email.split('@')[0]
  const userEmail = `${userName}@origin.com.au`.toLowerCase()

  const getMyRolesToggle = () => {
    if (isMyRolesToggleChecked !== undefined) {
      return isMyRolesToggleChecked
    }
    return tasksUseMyRolesToggleValue
  }

  const getLocalFilters = () => {
    if (localFilters) {
      return localFilters
    }
    return getSavedFilterList()
  }

  const getSortOrder = () => {
    if (sortOrder) {
      return sortOrder
    }
    return getSavedSortOrder()
  }

  const getSearchTasksPayload = (taskStatus: string, from: number, to: number) => {
    const params = {
      status: taskStatus,
      searchText,
      localFilters: getLocalFilters(),
      wellId,
      globalFilters: applyGlobalFilters ? (globalFilters ?? [])[0] : [][0],
      tasksUseMyRolesToggleValue: getMyRolesToggle(),
      currentUserEmail: userEmail,
      currentUserRoles: authData?.roles,
    }
    const query = getQueryByAllFilters(params)
    const { name, direction } = getSortOrder()
    return {
      from,
      size: to,
      query,
      _source: fields || undefined,
      sort: sort
        ? [
            {
              [name]: {
                order: direction,
              },
            },
          ]
        : undefined,
    }
  }

  const mapResponse = resp => {
    if (resp.data.errorMessage) {
      throw new Error(resp.data.errorMessage)
    }
    return resp.data.body.hits.map(r => {
      // eslint-disable-next-line no-underscore-dangle
      return r._source
    })
  }

  try {
    const requests = statuses.map(sts =>
      call(searchTasks, getSearchTasksPayload(sts, currentPage * rowsPerPage, rowsPerPage))
    )

    let tasksByStatus = {}
    let totalTasksByStatus = {}
    const response = yield all(requests)
    const mappedResponse = response.map(mapResponse)

    for (const status of statuses) {
      const index = statuses.indexOf(status)

      tasksByStatus = {
        ...tasksByStatus,
        [status]: mappedResponse[index].map(mapTask),
      }

      totalTasksByStatus = {
        ...totalTasksByStatus,
        [status]: response[index].data.body.count.count ?? 0,
      }
    }

    yield put({
      type: GET_OPENSEARCH_TASKS_BY_WELL_SUCCESS,
      payload: { tasksByStatus, totalTasksByStatus },
    })
  } catch (error) {
    console.log(error)
    yield all([
      put({ type: GET_OPENSEARCH_TASKS_BY_WELL_ERROR, payload: error }),
      put(enqueueErrorMessage({ message: 'Failed to load Tasks By Well', error })),
    ])
  }
}

function* getOpenSearchTasksSaga({ payload: request = {} }: GetTasksAction) {
  const statuses = isString(request.status) ? [request.status] : request.status
  const {
    rowsPerPage,
    currentPage,
    searchText,
    localFilters,
    wellId,
    isMyRolesToggleChecked,
    sortOrder,
    sort = true,
    fields = null,
  } = request

  const authData = yield select(state => state?.auth?.data)
  const tasksUseMyRolesToggleValue = yield select(
    state => state?.config?.settings?.tasks?.useMyRoles
  )
  const globalFilters = yield select(state => state?.filters?.filters)
  const userName = authData?.email.split('@')[0]
  const userEmail = `${userName}@origin.com.au`.toLowerCase()

  const getMyRolesToggle = () => {
    if (isMyRolesToggleChecked !== undefined) {
      return isMyRolesToggleChecked
    }
    return tasksUseMyRolesToggleValue
  }

  const getLocalFilters = () => {
    if (localFilters) {
      return localFilters
    }
    return getSavedFilterList()
  }

  const getSortOrder = () => {
    if (sortOrder) {
      return sortOrder
    }
    return getSavedSortOrder()
  }

  const getSearchTasksPayload = (taskStatus: string, from: number, to: number) => {
    const params = {
      status: taskStatus,
      searchText,
      localFilters: getLocalFilters(),
      wellId,
      globalFilters: (globalFilters ?? [])[0],
      tasksUseMyRolesToggleValue: getMyRolesToggle(),
      currentUserEmail: userEmail,
      currentUserRoles: authData?.roles,
    }

    const query = getQueryByAllFilters(params)
    const { name, direction } = getSortOrder()

    return {
      from,
      size: to,
      query,
      _source: fields || undefined,
      sort: sort
        ? [
            {
              [name]: {
                order: direction,
              },
            },
          ]
        : undefined,
      otherFilters: {
        designatedPEs: (globalFilters ?? [])[0].designatedPEs || [],
      },
    }
  }

  const mapResponse = resp => {
    if (resp.data.errorMessage) {
      throw new Error(resp.data.errorMessage)
    }
    return resp.data.body.hits.map(r => {
      // eslint-disable-next-line no-underscore-dangle
      return r._source
    })
  }

  try {
    const requests = statuses.map(sts =>
      call(searchTasks, getSearchTasksPayload(sts, currentPage * rowsPerPage, rowsPerPage))
    )

    let tasksByStatus = {}
    let totalTasksByStatus = {}
    const response = yield all(requests)
    const mappedResponse = response.map(mapResponse)

    for (const status of statuses) {
      const index = statuses.indexOf(status)

      tasksByStatus = {
        ...tasksByStatus,
        [status]: mappedResponse[index].map(mapTask),
      }

      totalTasksByStatus = {
        ...totalTasksByStatus,
        [status]: response[index].data.body.count.count ?? 0,
      }
    }

    yield put({ type: GET_TASKS_SUCCESS, payload: { tasksByStatus, totalTasksByStatus } })
  } catch (error) {
    yield all([
      put({ type: GET_TASKS_ERROR, payload: error }),
      put(enqueueErrorMessage({ message: 'Failed to load Tasks', error })),
    ])
  }
}

function* getDashboardOpenSearchTasksSaga({ payload: request = {} }: GetTasksAction) {
  const statuses = isString(request.status) ? [request.status] : request.status
  const {
    rowsPerPage,
    currentPage,
    searchText,
    localFilters,
    wellId,
    isMyRolesToggleChecked,
    sortOrder,
    sort = true,
    fields = null,
    updatedAt,
    updatedBy,
    completedBy,
    completedAt,
  }: any = request

  const authData = yield select(state => state?.auth?.data)
  const tasksUseMyRolesToggleValue = yield select(
    state => state?.config?.settings?.dashboard?.useMyRoles
  )
  const globalFilters = yield select(state => state?.filters?.filters)
  const userName = authData?.email.split('@')[0]
  const userEmail = `${userName}@origin.com.au`.toLowerCase()

  const getMyRolesToggle = () => {
    if (isMyRolesToggleChecked !== undefined) {
      return isMyRolesToggleChecked
    }
    return tasksUseMyRolesToggleValue
  }

  const getLocalFilters = () => {
    if (localFilters) {
      return localFilters
    }
    return getSavedFilterList()
  }

  const getSortOrder = () => {
    if (sortOrder) {
      return sortOrder
    }
    return getSavedSortOrder()
  }

  const getSearchTasksPayload = (taskStatus: string, from: number, to: number) => {
    const params = {
      status: taskStatus,
      searchText,
      localFilters: getLocalFilters(),
      wellId,
      globalFilters: (globalFilters ?? [])[0],
      tasksUseMyRolesToggleValue: getMyRolesToggle(),
      currentUserEmail: userEmail,
      currentUserRoles: authData?.roles,
      updatedAt,
      updatedBy,
      completedBy,
      completedAt,
      isDashboard: true,
    }
    // console.log(params)
    const query = getQueryByAllFilters(params)
    const { name, direction } = getSortOrder()
    return {
      from,
      size: to,
      query,
      _source: fields || undefined,
      sort: sort
        ? [
            {
              [name]: {
                order: direction,
              },
            },
          ]
        : undefined,
    }
  }

  const mapResponse = resp => {
    if (resp.data.errorMessage) {
      throw new Error(resp.data.errorMessage)
    }
    return resp.data.body.hits.map(r => {
      // eslint-disable-next-line no-underscore-dangle
      return r._source
    })
  }

  try {
    const requests = statuses.map(sts =>
      call(searchTasks, getSearchTasksPayload(sts, currentPage * rowsPerPage, rowsPerPage))
    )

    let tasksByStatus = {}
    let totalTasksByStatus = {}
    const response = yield all(requests)
    const mappedResponse = response.map(mapResponse)

    for (const status of statuses) {
      const index = statuses.indexOf(status)

      tasksByStatus = {
        ...tasksByStatus,
        [status]: mappedResponse[index].map(mapTask),
      }

      totalTasksByStatus = {
        ...totalTasksByStatus,
        [status]: response[index].data.body.count.count ?? 0,
      }
    }

    yield put({ type: GET_DASHBOARD_TASKS_SUCCESS, payload: { tasksByStatus, totalTasksByStatus } })
  } catch (error) {
    yield all([
      put({ type: GET_DASHBOARD_TASKS_ERROR, payload: error }),
      put(enqueueErrorMessage({ message: 'Failed to load Tasks', error })),
    ])
  }
}

// Used within Dashboard / Wells screen
function* getOpenSearchWellsByOpenTasksSaga({ payload: request = {} }: any) {
  const { isMyRolesToggleChecked } = request

  const authData = yield select(state => state?.auth?.data)

  const tasksUseMyRolesToggleValue = yield select(
    state => state?.config?.settings?.dashboard?.useMyRoles
  )

  const globalFilters = yield select(state => state?.filters?.filters)
  const userName = authData?.email.split('@')[0]
  const userEmail = `${userName}@origin.com.au`.toLowerCase()

  const getMyRolesToggle = () => {
    if (isMyRolesToggleChecked !== undefined) {
      return isMyRolesToggleChecked
    }
    return tasksUseMyRolesToggleValue
  }

  const getSearchTasksPayload = () => {
    const params = {
      statuses: [STATUS.IN_PROGRESS, STATUS.TO_DO],
      globalFilters: (globalFilters ?? [])[0],
      tasksUseMyRolesToggleValue: getMyRolesToggle(),
      currentUserEmail: userEmail,
      currentUserRoles: authData?.roles,
    }
    const query = getQueryByAllFilters(params)
    return {
      query,
      _source: false,
      aggs: {
        wellsByOpenTasks: {
          terms: {
            field: 'wellId',
            size: 10000,
          },
        },
      },
    }
  }

  const mapResponse = resp => {
    if (resp.data.errorMessage) {
      throw new Error(resp.data.errorMessage)
    }
    return resp.data.body.aggregations?.wellsByOpenTasks
  }

  try {
    const response = yield call(searchTasks, getSearchTasksPayload())
    const mappedResponse = mapResponse(response)

    yield put({ type: GET_WELLS_BY_OPENTASKS_SUCCESS, payload: mappedResponse })
  } catch (error) {
    yield all([
      put({ type: GET_WELLS_BY_OPENTASKS_ERROR, payload: error }),
      put(enqueueErrorMessage({ message: 'Failed to load wells by open tasks', error })),
    ])
  }
}

function* updateTaskSaga(action) {
  const { payload } = action

  const view = Qs.get().view as string
  const { useMyRoles, applyGlobalFilters } = yield select(state => state.config.settings[view])

  const savedFilter = Qs.get().filter as string
  const filterList = getSavedFilterList() // this is localFilters

  const searchText = (Qs.get().search as string) || ''

  // figure out the code here??
  const sortOrder = getSavedSortOrder() || { name: 'priority', direction: 'desc' }

  const { wellId } = window.location.pathname.match(/\/well\/(?<wellId>.*)/).groups

  const fetchPayload = {
    wellId,
    status: [IN_PROGRESS, TO_DO, ESCALATED, CLOSED, EXPIRED],
    localFilters: filterList,
    sortOrder,
    rowsPerPage: DEFAULT_ROWS_PER_PAGE,
    currentPage: 0,
    isMyRolesToggleChecked: useMyRoles,
    searchText,
    applyGlobalFilters,
  }

  // console.log('fetch payload', fetchPayload)

  try {
    const user = yield select(state => state.auth.data)

    const response = yield call(updateTask, payload)
    yield put({ type: UPDATE_TASK_SUCCESS, payload: mapTask(response.data.body), user })

    if (payload.to) {
      const { wellId, taskType, from, to, disregardedNote, note } = payload
      yield put({
        type: suspensions.CREATE_REQUEST,
        payload: { wellId, taskType, from, to, notes: disregardedNote || note },
      })
    }

    if (!fetchPayload.wellId) {
      throw new Error('No wellId available in updateTask Saga')
    }

    yield call(getOpenSearchTasksByWellSaga, fetchOpenSearchTasksByWell(fetchPayload))
  } catch (error) {
    yield all([
      put({ type: UPDATE_TASK_ERROR, payload: error }),
      put(enqueueErrorMessage({ message: 'Failed to update Tasks', error })),
    ])
  }
}

function* createTaskSaga(action) {
  const { payload } = action
  const { taskType, status } = payload
  const view = Qs.get().view as string
  const { useMyRoles, applyGlobalFilters } = yield select(state => state.config.settings[view])
  const savedFilter = Qs.get().filter as string
  const filterList = getSavedFilterList() // this is localFilters

  const searchText = (Qs.get().search as string) || ''

  // figure out the code here??
  const sortOrder = getSavedSortOrder() || { name: 'priority', direction: 'desc' }

  const { wellId } = window.location.pathname.match(/\/well\/(?<wellId>.*)/).groups

  const fetchPayload = {
    wellId,
    status: [IN_PROGRESS, TO_DO, ESCALATED, CLOSED, EXPIRED],
    localFilters: filterList,
    sortOrder,
    rowsPerPage: DEFAULT_ROWS_PER_PAGE,
    currentPage: 0,
    isMyRolesToggleChecked: useMyRoles,
    searchText,
    applyGlobalFilters,
  }

  try {
    const response = yield call(createTask, payload)
    const task = mapTask(response.data.body)

    const user = yield select(state => state.auth.data)
    yield put({ type: CREATE_TASK_SUCCESS, payload: { ...task, lastNote: payload.note }, user })
    const note = {
      taskId: task.taskId,
      note: payload.note,
      action: taskType === 'Manual Task' && status !== COMPLETED ? 'Escalated' : 'Note added',
      isUpdate: false,
    }
    yield put({ type: POST_NOTES, payload: note })

    //
    if (!fetchPayload.wellId) {
      throw new Error('No wellId available in updateTask Saga')
    }

    yield call(getOpenSearchTasksByWellSaga, fetchOpenSearchTasksByWell(fetchPayload))
  } catch (error) {
    yield all([
      put({ type: CREATE_TASK_ERROR, payload: error }),
      put(enqueueErrorMessage({ message: 'Failed to create Tasks', error })),
    ])
  }
}

function getNotesForTask(taskId) {
  const url = `/task-notes?taskId=${taskId}`
  return axios
    .get(url)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

function* fetchNotesForTask(action) {
  const taskId = action.payload
  const { response, error } = yield call(getNotesForTask, taskId)

  if (error) {
    yield all([
      put({ type: GET_NOTES_FOR_TASK_ERROR, payload: error }),
      put(
        enqueueErrorMessage({
          message: 'Failed to load Notes for task',
          error,
        })
      ),
    ])
  } else {
    yield put({
      type: GET_NOTES_FOR_TASK_SUCCESS,
      payload: { taskId, notes: response.data.body.data },
    })
  }
}

function createNotes({ taskId, note, action, noteId, isUpdate }) {
  return axios({
    method: isUpdate ? 'patch' : 'post',
    url: '/task-notes',
    data: {
      taskId,
      note,
      action,
      noteId,
    },
  })
    .then(response => response.data.body)
    .catch(error => ({ error }))
}

function* postNotes(action) {
  const data = { ...action.payload }
  try {
    const note = yield call(createNotes, data)

    yield all([
      put({ type: POST_NOTES_SUCCESS, payload: note }),
      put({ type: GET_TASK, payload: { taskId: data.taskId, filter: false } }),
    ])
  } catch (error) {
    yield all([
      put({ type: POST_NOTES_ERROR, payload: error }),
      put(
        enqueueErrorMessage({
          message: 'Failed to create Note',
          error,
        })
      ),
    ])
  }
}

function* taskLocationChangeSaga(action) {
  const pathname = action.payload?.location?.pathname
  if (pathname !== '/tasks') {
    yield put({
      type: CLEAR_OPENSEARCH_TASKS,
    })
  }
}

function* watchSagas() {
  yield takeEvery(GET_TASK, getTaskSaga)
  yield takeEvery(GET_TASKS, getTasksSaga)
  yield takeEvery(GET_OPENSEARCH_TASKS, getOpenSearchTasksSaga)
  yield takeEvery(GET_OPENSEARCH_TASKS_BY_WELL, getOpenSearchTasksByWellSaga)
  yield takeEvery(GET_DASHBOARD_OPENSEARCH_TASKS, getDashboardOpenSearchTasksSaga)
  yield takeEvery(GET_WELLS_BY_OPENTASKS, getOpenSearchWellsByOpenTasksSaga)
  yield takeEvery(GET_TASKS_SILENT, getTasksSaga)
  yield takeLatest(POST_NOTES, postNotes)
  yield takeLatest(UPDATE_TASK, updateTaskSaga)
  yield takeLatest(CREATE_TASK, createTaskSaga)
  yield takeLeading(GET_NOTES_FOR_TASK, fetchNotesForTask)
  yield takeEvery('@@router/LOCATION_CHANGE', taskLocationChangeSaga)
}

export const tasksSagas = [watchSagas]
