import { createMuiTheme } from '@material-ui/core/styles'
import deepOrange from '@material-ui/core/colors/deepOrange'
import amber from '@material-ui/core/colors/amber'

export default createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: deepOrange,
    secondary: amber,
  },
})
