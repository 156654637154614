import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import axios from 'axios'
import { SnackbarProvider } from 'notistack'
import { ThemeProvider } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateUtils from '@date-io/dayjs'

import { history, store } from './reducers/configureStore'
import { sanitiseUrl } from './utils'
import theme from './theme'

axios.defaults.baseURL = sanitiseUrl(process.env.API_BASE_URI)

async function startApplication() {
  const { default: App } = await import('./components/App')

  render(
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <MuiPickersUtilsProvider utils={DateUtils}>
          <ConnectedRouter history={history}>
            <SnackbarProvider
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              autoHideDuration={4000}
            >
              <App />
            </SnackbarProvider>
          </ConnectedRouter>
        </MuiPickersUtilsProvider>
      </Provider>
    </ThemeProvider>,
    document.getElementById('root')
  )
}

startApplication()
